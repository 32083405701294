.navbar {
  position: sticky;
  width: 60%;
  background-color: #252734;
  min-height: 80px;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100px;
      height: 18px;
    }
  }
}

@media only screen and (max-width: 899px) {
  .navbar {
    width: 100%;
    
    .content {
      justify-content: end;
    }
  }
}
