.title {
  font-size: 75px;
  color: white;
  font-family: cursive;
}

@media only screen and (max-width: 800px) {
  .title {
    font-size: 25px;
  }
}
