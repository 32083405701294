.drawer {
  .drawerPaper {
    background-color: rgba(0,0,0,.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;

    .drawerHeader {
      display: flex;
      justify-content: start;
    }

    .drawerContent {
      width: 300px;
      height: 100vh;
      background-color: #252734;
    }
  }
}