.sectionHeader {
  display: flex;
  flex-direction: column;
  align-items: start;

  .label {
    color: #858684;
    font-size: 15px;
  }

  .title {
    max-width: 100%;
    text-align: start;
    color: white;
    font-size: 45px !important;
    font-family: cursive;
  }
}

@media only screen and (max-width: 1050px) {
  .sectionHeader {
    .label {
      color: #858684;
      font-size: 15px;
    }

    .title {
      max-width: 100%;
      font-size: 20px !important;
    }
  }
}
