.ContactCard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  background-color: #252734;

  .title {
    font-size: 15px;
    color: #fff;
    font-family: Poppins,sans-serif;
    font-weight: 500;
  }

  .contact {
    color: #858792;
    font-size: 15px;
  }
}
