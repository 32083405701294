.SpecialSkills {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-top: 150px;
  align-items: center;
  z-index: -40;

  .skills {
    width: 400px;
  }
}

  @media screen and (max-width: 900px) {
  .SpecialSkills {
    flex-direction: column;
    text-align: start;
  }

  .skills {
    width: 100% !important;
    padding: 0px 10px;
  }
}