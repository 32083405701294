.App {
  position: relative;
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -20px;
}
