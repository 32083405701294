.EmailForm {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: start;

  .FormInputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1050px) {
  .EmailForm {
    padding-left: 0px;

    .getInTouch {
      color: #858684;
      font-size: 15px;
    }

    .hint {
      max-width: 70%;
      text-align: start;
      color: white;
      font-size: 20px;
      font-family: cursive;
    }

    .FormInputs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
