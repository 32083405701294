.home {
  width: 100%;
  margin-top: 50px;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;

  .intro {
    width: 65%;
    display: flex;
    flex-direction: column;

    .fullName {
      color: white;
      font-size: 65px;
      width: fit-content;
      font-family: cursive;
    }

    .position {
      width: fit-content;
      color: #5f5f65;
      font-size: 25px;
      margin-top: 10px;
    }

      .resume {
        color: white;
        text-decoration: none;
        font-size: 12px;
        margin-right: 12px;
      }
  }
}

@media screen and (max-width: 800px) {
  .home {
    .intro {
      align-items: center;
      .fullName {
        font-size: 35px;
        text-align: center;
      }

      .position {
        font-size: 20px;
      }
    }
  }
}