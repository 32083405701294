.myServices {
  background-color: #252734;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;

  .header {
    display: flex;
    align-items: center;

    .logo {
      width: 75px;
      height: 75px;
      margin-right: 30px;
    }
  }

  .content {
    margin-top: 50px;
    background-color: #2a2c39;
    padding: 20px;
    width: 600px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 800px) {
  .myServices {
    .header {
      .logo {
        width: 40px;
        height: 40px;
      }
    }

    .content {
      padding: 10px;
      width: auto;
      justify-content: space-around;
    }
  }
}
