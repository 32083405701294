.fastContacts {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  flex-wrap: wrap;

  .contactContainer {
    display: flex;
    align-items: center;

    .contact {
      margin-left: 20px;
      color: white;
      height: fit-content;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .fastContacts {
    flex-direction: column;
    align-items: start;
    padding-left: 10px;

    .contactContainer {
      .contact {
        font-size: 16px;
      }
    }
  }
}