.linkContainer {
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .link {
    color: #858792;
    text-decoration: none;
  }

  .linkActive {
    color: white;
    text-decoration: none;
  }
}

@media only screen and (max-width: 899px) {
  .linkContainer {
    width: 100%;
    height: 30px;

    .link {
      font-size: 20px;
    }

    .linkActive {
      font-size: 20px;
    }
  }
}

