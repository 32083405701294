.button {
  width: fit-content;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 8px 60px;
  border: 1px solid #3b3d49;
  border-radius: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: transparent;
  margin-top: 30px;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
}

.button:hover {
  background-color: #5d5e65;
}

.fastContactBtn {
  width:  60px;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #3b3d49;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  margin-top: 0px;
  border-radius: 50%;
  padding: 17px;
  margin-right: 10px;
}

.fastContactBtn:hover {
  background-color: #5d5e65;
}
