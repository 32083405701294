.card {
  display: flex;
  align-items: center;
  width: 300px;

  .icon {
    width: 70px;
    height: 70px;
    border: 1px solid white;
    border-radius: 10px;
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #858792;
    font-family: cursive;
  }

  .count {
    margin-bottom: 25px;
    color: white;
    font-size: 50px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 899px) {
  .card {
    margin: 20px;
    width: 100%;
  }
}
