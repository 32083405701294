.projectLink {
  width: 100%;
  display: flex;
  justify-content: start;
  text-decoration: none !important;
  align-items: center;
  background-color: #2a2c39;
  margin-top: 30px !important;

  .projectCard {
    display: flex;
    align-items: center;
    padding: 40px;
    width: 100%;

    .imgContainer {
      width: 200px;
      height: 100%;
      padding: 0px 15px;

      .projectImg {
        width: 100%;
        height: 100%;
        margin-right: 10px;
      }
    }

    .projectInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0px 10px 10px;
      text-align: start;

      .header {
        display: flex;
        align-items: center;

        .title {
          font-size: 16px;
          color: white;
        }

        .date {
          font-size: 13px;
          color: grey;
        }
      }

      .description {
        font-size: 22px;
        font-family: cursive;
        color: white;
        margin-top: 40px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .projectLink {
    .projectCard {
      width: 100%;
      padding: 10px;
      height: auto;
      flex-direction: column;

      .imgContainer {
        height: 60px;
      }

      .projectInfo {
        height: auto;
        margin-top: 15px;
      }

      .description {
        font-size: 18px;
        margin-top: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
